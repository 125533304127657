import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

const Contact = () => {
  return (
    <Layout>
      <div
        className="relative mt-2"
        style={{ height: 85 + "vh" }}
        id="companyMap"
      >
        <StaticImage
          className="w-full h-full"
          src="../images/googlemap.png"
          alt="item-marker"
        ></StaticImage>
        <button
          className="shadow-lg rounded-md font-bold leading-none text-center bg-texpressblue text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-7 ml-1 px-10 py-2"
          onClick={() =>
            window.open(
              "https://www.google.com/maps/dir/34.0170213,-118.0771512/14936+Dillow+St,+Westminster,+CA+92683/@33.8802584,-118.2275833,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x80dd27b5fd9b7285:0xf3926758f76684d!2m2!1d-117.9659495!2d33.7458817"
            )
          }
        >
          Visit Us
        </button>
        <div className="bg-white absolute border-2 w-4/5 md:w-2/6 left-10 top-20 md:right-10 md:left-auto p-4 shadow-md rounded-sm">
          <h4>Liên Hệ</h4>
          <p className="p-0 mb-1">
            14936 Dillow St Westminster, California 92683
          </p>
          <p className="p-0 mb-1">Mở cửa 7 ngày 1 tuần</p>
          <p className="p-0 m-0">Từ 10:00 AM đển 6:00 PM</p>

          <form name="form" className="flex flex-col">
            <div className="flex flex-col w-full md:w-3/5 mt-2">
              <label htmlFor="choose_from_by" className="font-bold text-sm">
                Name - Tên Khách Hàng (*):
              </label>
              <input
                type="text"
                className="border-2 p-1 border-gray-200 radius-2 rounded-sm"
              />
            </div>
            <div className="flex flex-col w-full md:w-3/5 mt-2">
              <label htmlFor="tinhThanh" className="font-bold text-sm">
                Phone - Số Điện Thoại (*)
              </label>
              <input
                type="text"
                className="border-2 p-1 border-gray-200 radius-2 rounded-sm"
              />
            </div>
            <div className="flex flex-col w-full md:w-3/5 mt-2">
              <label htmlFor="tinhThanh" className="font-bold text-sm">
                Note - Nội Dung (*)
              </label>
              <textarea
                type="text"
                className="border-2 p-1 border-gray-200 radius-2 rounded-sm"
              />
            </div>
            <div
              className="flex flex-row w-full mt-2"
              style={{
                gap: 5,
                alignItems: "flex-start",
              }}
            >
              <input
                type="checkbox"
                className="border-2 p-1 border-gray-200 radius-2 rounded-sm mt-1"
                value=""
                id="policy"
              />
              <label htmlFor="policy" className="font-bold text-xs">
                By checking this box, I consent to receive SMS messages from
                Dolphin Pool Maintenance. I understand that Message and data
                rates may apply and that I may reply STOP to opt-out of future
                messaging; reply HELP for additional messaging help. Message
                frequency may vary depending on the interaction between you and
                our agents. See our Privacy Policy.
              </label>
            </div>
            <button
              type="submit"
              className="mt-3 text-sm font-bold leading-none text-center bg-texpressblue text-white w-2/5 md:w-1/5 h-8"
            >
              Send
            </button>
          </form>
        </div>
      </div>
      <div className="h-20 w-1"></div>
    </Layout>
  )
}

export default Contact
